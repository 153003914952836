import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";
import { DomSanitizer, SafeHtml, SafeUrl } from "@angular/platform-browser";
import { orderBy, sortBy } from "lodash";
import { enum_data } from "./enumData";
import { CommonService } from "../_services/common.services";

@Pipe({
  name: "shortNumber",
})
export class ShortNumberPipe implements PipeTransform {
  transform(num: number, args?: any): any {
    if (isNaN(num)) {
      return num;
    } // will only work value is a number
    if (num === null) {
      return "-";
    }
    if (num === 0) {
      return num;
    }
    let abs = Math.abs(num);
    const rounder = Math.pow(10, 1);
    const isNegative = num < 0; // will also work for Negative numbers
    let key = "";

    const powers = [
      { key: "Q", value: Math.pow(10, 15) },
      { key: "T", value: Math.pow(10, 12) },
      { key: "B", value: Math.pow(10, 9) },
      { key: "M", value: Math.pow(10, 6) },
      { key: "K", value: 1000 },
    ];

    for (const item of powers) {
      let reduced = abs / item.value;
      // reduced = Math.round(reduced * rounder) / rounder;
      if (reduced >= 1) {
        abs = +reduced.toFixed(2);
        key = item.key;
        break;
      }
    }
    return (isNegative ? "-" : "") + abs + key;
  }
}

@Pipe({
  name: 'findByKey',
})
export class FindByKeyPipe implements PipeTransform {
  constructor() {
  }

  transform(value: string | string[], key: string, source: any[]): any {
    if (Array.isArray(value)) {
      return value.map(item => source.find(sourceItem => sourceItem[key] === item));
    }
    return source.find(sourceItem => sourceItem[key] === value);
  }
}

@Pipe({
  name: "tofixed",
})
export class ToFixedPipe implements PipeTransform {
  transform(num: number, args?: any): any {
    let decimals = 2;
    if (isNaN(num)) {
      return num;
    } // will only work value is a number
    if (num === null) {
      return "-";
    }
    if (num === 0) {
      return num;
    }
    if (args) {
      decimals = args;
    }
    return num.toFixed(decimals);
  }
}
@Pipe({
  name: "firewallType",
})
export class FirewallTypePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return;
    }
    // Add a space before each capital letter
    return value.split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}
@Pipe({
  name: "securityGrade",
})
export class SecurityGradePipe implements PipeTransform {
  transform(value: any): string {
    if (!value) {
      return;
    }
    return value.security_grade;
  }
}

@Pipe({
  name: "discoveryProtocol",
})
export class DiscoveryProtocolPipe implements PipeTransform {
  transform(value: any): string {
    if (typeof value === 'string') {
      value = JSON.parse(value);
    }
    if (!value || typeof value !== 'object' || !value.length) {
      return '';
    }
    // Add a space before each capital letter
    return value.filter((x: any) => x.status).map((y: any) => y.protocol).join(', ');
  }
}

@Pipe({
  name: "capitalSplit",
})
export class CapitalSplitPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    // Add a space before each capital letter
    return value.replace(/([A-Z])/g, " $1").trim();
  }
}
@Pipe({
  name: "reportNameData",
})
export class ReportNameDataPipe implements PipeTransform {
  transform(value: any): string {
    if (value.length === 0) {
      return "";
    } else {
      return value[0].displayReportName;
    }
  }
}
@Pipe({
  name: "sortBy",
  pure: true,
})
/*
 *ngFor="let c of oneDimArray | sortBy:'asc'"
 *ngFor="let c of arrayOfObjects | sortBy:'asc':'propertyName'"
 */
export class ArraySortPipe implements PipeTransform {
  transform(value: any[], order = "", column: string = ""): any[] {
    if (!value || order === "" || !order) {
      return value;
    } // no array
    if (value.length <= 1) {
      return value;
    } // array with only one item
    if (!column || column === "") {
      const sorted = this.sortOnCaseSensitivity(value, true);
      if (order === "asc") {
        return sorted.sort();
      } else {
        return sorted.sort().reverse();
      }
    } // sort 1d array
    const converted = this.convertMultiOnCaseSensitivity(value, column, true);
    // @ts-ignore
    return orderBy(converted, ["sortCol"], [order]).map((v: any) => {
      delete v["sortCol"];
      return v;
    });
  }
  sortOnCaseSensitivity(value: any, caseInsensitive: boolean): any[] {
    return sortBy(value, (v: any) => {
      if (typeof v === "string" && caseInsensitive) {
        return v.toLowerCase();
      }
      return v;
    });
  }
  convertMultiOnCaseSensitivity(
    value: any,
    column: any,
    caseInsensitive: boolean
  ): any {
    let converted = value.map((v: any) => ({ ...v, sortCol: v[column] }));
    if (caseInsensitive) {
      converted = value.map((v: any) => {
        if (typeof v[column] === "string") {
          return { ...v, sortCol: v[column].toLowerCase() };
        }
        return { ...v, sortCol: v[column] };
      });
    }
    return converted;
  }
}

@Pipe({
  name: "fileNameFilter",
  pure: true,
})
export class FileNameFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return value;
    }
    return value.split("\\").pop().split("/").pop().split(".")[0];
  }
}

@Pipe({
  name: "appBaseLine",
  pure: true,
})
export class AppBaseLineFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value || !value.length) { return value; }
    try {
      return value.map((a: any) => a.application_name).join(', ');
    } catch (e) {
      return value;
    }
  }
}

@Pipe({
  name: "portsData",
  pure: true,
})
export class PortsDataFilterPipe implements PipeTransform {
  constructor() { }
  transform(value: any, args?: any): any {
    if (!value) {
      return value;
    }
    if (typeof value !== "object") {
      return "-";
    }
    let retVal = 0;
    if (args) {
      retVal =
        value[args] && value[args].asset_count ? value[args].asset_count : 0;
    } else {
      return "-";
    }
    return retVal;
  }
}

@Pipe({
  name: "assetStatus",
  pure: true,
})
export class AssetStatusFilterPipe implements PipeTransform {
  constructor() { }
  transform(date: any, args?: any): any {
    if (date === null || !date || date === "" || date === "-") {
      return false;
    }
    const currentLocalTime = new Date();
    const utcDate: Date = new Date(date);
    const offsetMinutes: number = utcDate.getTimezoneOffset();
    const localDate: Date = new Date(utcDate.getTime() - offsetMinutes * 60000);
    const timeDifferenceInMillis = currentLocalTime.getTime() - localDate.getTime();
    const threeHoursInMillis = 3 * 60 * 60 * 1000;
    return timeDifferenceInMillis <= threeHoursInMillis;
  }
}

@Pipe({
  name: "velocity",
  pure: true,
})
export class VelocityFilterPipe implements PipeTransform {
  constructor() { }
  transform(value: any, args?: any): any {
    if (!value) {
      return value;
    }
    if (typeof value !== "object") {
      return "-";
    }
    let retVal = 0;
    if (args) {
      retVal = (value[args] && value[args].asset_count) ? value[args].asset_count : 0;
    } else {
      return "-";
    }
    return retVal;
  }
}

@Pipe({
  name: "highlight",
  pure: true
})
export class HighlightFilterPipe implements PipeTransform {
  transform(value: any, ...args): any {
    if (!value) {
      return '';
    } else {
      return `<span class="bg-yellow-200 text-black px-1 py-0.5 rounded">${value}</span>`
    }
  }
}

@Pipe({
  name: "draftToComplete",
  pure: true,
})
export class DraftToCompleteFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return (value && true && value !== '') ? 'COMPLETED' : 'DRAFT';
  }
}

@Pipe({
  name: "dateToDays",
  pure: true,
})
export class DateToDaysFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return value;
    }
    const currentDate = new Date();
    const timeDifference: any =
      new Date(value * 1000).getTime() - currentDate.getTime();
    return Math.floor(timeDifference / (24 * 60 * 60 * 1000));
  }
}
@Pipe({
  name: "objectToTime",
  pure: true,
})
export class ObjectToTimeFilterPipe implements PipeTransform {
  transform(settings: any): any {
    if (!settings || !settings.hour || !settings.mins) {
      return "";
    }

    const hour = settings.hour[0] || 0;
    const min = settings.mins[0] || 0;
    const sec = 0;

    // Convert to 12-hour format
    let formattedHour = (hour % 12 === 0 ? 12 : hour % 12)
      .toString()
      .padStart(2, "0");
    const amPm = hour < 12 ? "AM" : "PM";

    const formattedTime = `${formattedHour}:${min
      .toString()
      .padStart(2, "0")} ${amPm}`;
    // Special case for midnight (00:01) --->If AM / PM not needed
    // if (hour === 0 && min === 1) {
    //   formattedHour = '12';
    // } else {
    //   formattedHour = hour.toString().padStart(2, '0');
    // }

    // const formattedTime = `${formattedHour}:${min.toString().padStart(2, '0')}`;
    return formattedTime;
  }
}
@Pipe({
  name: "keyTransform",
})
export class KeyTransformPipe implements PipeTransform {
  transform(value: string): string {
    if (value.includes(".")) {
      return value.replace(/\./g, " ");
    } else {
      return value;
    }
  }
}
@Pipe({
  name: "filterUnique",
  pure: true,
})
export class UniqueFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value != null) {
      const uniqueArray = value.filter((el: any, index: any, array: any) => {
        return array.indexOf(el) === index;
      });
      return uniqueArray;
    }
  }
}

@Pipe({
  name: "filter",
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], field: string, value: string): any[] {
    if (!items) {
      return [];
    }
    if (!field || !value) {
      return items;
    }

    return items.filter((singleItem) =>
      singleItem[field].toLowerCase().includes(value.toLowerCase())
    );
  }
}

@Pipe({
  name: "dateAgo",
  pure: true,
})
export class DateAgoPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value || value === "") {
      return "-";
    }
    let content = "";
    const year = Math.floor(value / (365 * 24 * 3600));
    value = value % (365 * 24 * 3600);
    if (year > 0) {
      content += year + "y ";
    }
    const day = Math.floor(value / (24 * 3600));
    value = value % (24 * 3600);
    if (day > 0) {
      content += day + "d ";
    }
    const hour = Math.floor(value / 3600);
    value %= 3600;
    if (hour > 0) {
      content += hour + "h ";
    }
    const minutes = Math.floor(value / 60);
    value %= 60;
    if (minutes > 0 && !args) {
      content += minutes + "m ";
    }
    const seconds = Math.floor(value);
    if (seconds > 0 && !args) {
      content += seconds + "s ";
    }
    return content;
  }
}

@Pipe({ name: "noneToHyphen" })
export class NoneToHyphen implements PipeTransform {
  transform(data: string): string {
    return !data ? "-" : data;
  }
}

@Pipe({ name: "iso8601ToLocalTime" })
export class Iso8601ToLocalTimePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return "";
    }
    const date = new Date(value);
    return date.toLocaleString();
  }
}

@Pipe({ name: "decodeHtmlStr" })
export class DecodeHtmlStrPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  }
}

// @Pipe({ name: 'utcToLocale' })
// export class UtcToLocale implements PipeTransform {
//   transform(date: string): string {
//     if (date === null || !date || date === '' || date === '-') { return date; }
//     // if (date.indexOf('T') > -1 && date.indexOf('Z') === -1) {
//     //     date += 'Z';
//     // }
//     // Create a Date object from the UTC time string
//     const utcDate: Date = new Date(date);
//     // Get the local time zone offset in minutes
//     const offsetMinutes: number = utcDate.getTimezoneOffset();
//     // Apply the offset to convert UTC time to local time
//     const localDate: Date = new Date(utcDate.getTime() - offsetMinutes * 60000);
//     return date ? localDate.toLocaleDateString() + ' ' + localDate.toLocaleTimeString() : date;
//   }
// }
@Pipe({
  name: 'addTime'
})
export class AddTimePipe implements PipeTransform {

  transform(value: string, hoursToAdd: number, minutesToAdd: number): string {
    if (!value) return value;

    // Parse the given UTC date string
    const date = new Date(value);

    // Add hours and minutes in UTC to avoid local timezone issues
    date.setUTCHours(date.getUTCHours()); //+ hoursToAdd);
    date.setUTCMinutes(date.getUTCMinutes()); //+ minutesToAdd);

    // Format the resulting date
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    }).format(date);

    return formattedDate;
  }
}

@Pipe({
  name: 'timeConverter'
})
export class TimeConverterPipe implements PipeTransform {
  transform(value: number): string {
    if (!value || value <= 0) {
      return ''; // Return an empty string if value is 0 or less
    }

    const mins = Math.floor(value / 60);
    const secs = value % 60;
    return `${mins} mins ${secs} secs`;
  }
}
@Pipe({ name: "ToLocale" })
export class ToLocale implements PipeTransform {
  transform(date: string): string {
    var original = JSON.parse(JSON.stringify(date))
    const format = localStorage.getItem("customisedDateFormat");
    if (date === null || !date || date === "" || date === "-") {
      return date;
    }
    if (date && date.indexOf('+') > 0) { date = date.split("+")[0] }

    const dateObject = new Date(date);
    const day = dateObject.getDate().toString().padStart(2, "0");
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const year = dateObject.getFullYear();
    switch (format) {
      case "shortDate":
        return (
          `${month}/${day}/${year}` + " " + dateObject.toLocaleTimeString()
        );
      case "mediumDate":
        return (
          `${day}/${month}/${year}` + " " + dateObject.toLocaleTimeString()
        );
      case "yyyy-MM-dd":
        const isoDate = dateObject.toISOString();
        return (
          isoDate.split("T")[0].replace(/-/g, "/") +
          " " +
          dateObject.toLocaleTimeString()
        );
      default:
        const utcDate: Date = new Date(date);
        // Get the local time zone offset in minutes
        const offsetMinutes: number = utcDate.getTimezoneOffset();
        // Apply the offset to convert UTC time to local time
        var localDate: Date = new Date(
          utcDate.getTime() + offsetMinutes * 60000
        );
        if (original.indexOf("+")) {
          localDate = new Date(
            utcDate.getTime()
          );
        }
        return date
          ? localDate.toLocaleDateString() +
          " " +
          localDate.toLocaleTimeString()
          : date;
      // return dateObject.toLocaleDateString() + ' ' + dateObject.toLocaleTimeString();
    }
  }
}
@Pipe({ name: "utcToLocale" })
export class UtcToLocale implements PipeTransform {
  transform(date: string): string {
    const format = localStorage.getItem("customisedDateFormat");
    if (date === null || !date || date === "" || date === "-") {
      return date;
    }
    // if (date && date.indexOf('Z') === -1) { date += 'Z'; }

    const dateObject = new Date(date);
    const day = dateObject.getDate().toString().padStart(2, "0");
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const year = dateObject.getFullYear();
    switch (format) {
      case "shortDate":
        return (
          `${month}/${day}/${year}` + " " + dateObject.toLocaleTimeString()
        );
      case "mediumDate":
        return (
          `${day}/${month}/${year}` + " " + dateObject.toLocaleTimeString()
        );
      case "yyyy-MM-dd":
        const isoDate = dateObject.toISOString();
        return (
          isoDate.split("T")[0].replace(/-/g, "/") +
          " " +
          dateObject.toLocaleTimeString()
        );
      default:
        const utcDate: Date = new Date(date);
        // Get the local time zone offset in minutes
        const offsetMinutes: number = utcDate.getTimezoneOffset();
        // Apply the offset to convert UTC time to local time
        const localDate: Date = new Date(
          utcDate.getTime() - offsetMinutes * 60000
        );
        return date
          ? localDate.toLocaleDateString() +
          " " +
          localDate.toLocaleTimeString()
          : date;
      // return dateObject.toLocaleDateString() + ' ' + dateObject.toLocaleTimeString();
    }
  }
}
@Pipe({ name: "utcToLocaleDate" })
export class UtcToLocaleDate implements PipeTransform {
  transform(dateString: string): string {
    if (!dateString || dateString === "" || dateString === "-") {
      return dateString;
    }

    const date = new Date(dateString);
    const offsetMinutes: number = date.getTimezoneOffset();
    const localDate: Date = new Date(date.getTime() - offsetMinutes * 60000);
    return this.formatDate(localDate);
  }

  formatDate(date: Date): string {
    const monthAbbreviation = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const amPm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // 0 should be treated as 12

    // Format the date string
    const formattedDate = `${monthAbbreviation} ${day} ${year} ${hours}:${minutes.toString().padStart(2, '0')} ${amPm}`;

    return formattedDate;
  }
}
@Pipe({ name: "utcToLocaleRDate" })
export class UtcToLocaleRDate implements PipeTransform {
  transform(date: string): string {
    if (date === null || !date || date === "" || date === "-") {
      return date;
    }
    const utcDate: Date = new Date(date);
    const offsetMinutes: number = utcDate.getTimezoneOffset();
    const localDate: Date = new Date(utcDate.getTime() - offsetMinutes * 60000);
    const s = date
      ? localDate.toLocaleDateString() + " " + localDate.toLocaleTimeString()
      : date;
    return this.formatDate(localDate);
  }
  formatDate(date) {
    // var date = new Date(inputDate);

    var monthAbbreviation = date.toLocaleString("default", { month: "short" });
    var day = date.getDate();
    var year = date.getFullYear();

    var hours = date.getHours();
    var minutes = date.getMinutes();

    // Determine whether it's AM or PM
    var amPm = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format and pad with leading zero
    hours = hours % 12;
    hours = hours ? hours : 12; // 0 should be treated as 12
    hours = hours.toString().padStart(2, "0");

    // Pad minutes with leading zero
    minutes = minutes.toString().padStart(2, "0");

    // Format the date string
    var formattedDate = `${monthAbbreviation} ${day} ${year} ${hours}:${minutes} ${amPm}`;

    return formattedDate;
  }
}

@Pipe({ name: "utcToLocaleHTML" })
export class UtcToLocaleHTML implements PipeTransform {
  transform(date: string): string {
    if (date === null || !date || date === "" || date === "-") {
      return date;
    }
    const dateObject = new Date(date);
    const utcDate: Date = new Date(date);
    // Get the local time zone offset in minutes
    const offsetMinutes: number = utcDate.getTimezoneOffset();
    // Apply the offset to convert UTC time to local time
    const localDate: Date = new Date(utcDate.getTime() - 0);
    return date
      ? localDate.toLocaleDateString() + " " + localDate.toLocaleTimeString()
      : date;
    // return dateObject.toLocaleDateString() + ' ' + dateObject.toLocaleTimeString();
  }
}
@Pipe({ name: "tTime" })
export class TTimePipe implements PipeTransform {
  transform(date: string): string {
    if (date === null || !date || date === "" || date === "-") {
      return date;
    }
    // Create a Date object from the UTC time string
    const utcDate: Date = new Date(date + ":00");
    // Get the local time zone offset in minutes
    const offsetMinutes: number = utcDate.getTimezoneOffset();
    // Apply the offset to convert UTC time to local time
    const localDate: Date = new Date(utcDate.getTime() - offsetMinutes * 60000);
    return date
      ? localDate.toLocaleDateString() + " " + localDate.toLocaleTimeString()
      : date;
  }
}

@Pipe({ name: "formatCell" })
export class FormatCellPipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    private cs: CommonService
  ) { }
  transform(value: any, format: string): any {
    if (value === undefined) {
      return "";
    }
    if (format === "date") {
      return this.datePipe.transform(value, "medium");
    } else if (format === "uppercase") {
      return new UppercasePipe().transform(value);
    } else if (format === "appbaseline") {
      return new AppBaseLineFilterPipe().transform(value);
    } else if (format && format.indexOf("ports") > -1) {
      const params = format.split(":");
      return new PortsDataFilterPipe().transform(value, params[1]);
    } else if (format && format.indexOf("assetstatus") > -1) {
      return new AssetStatusFilterPipe().transform(value);
    } else if (format && format.indexOf("velocity") > -1) {
      const params = format.split(":");
      return new VelocityFilterPipe().transform(value, params[1]);
    } else if (format === "cameltohuman") {
      return new CamelToHumanPipe().transform(value, true);
    } else if (format === "remeaction") {
      return new RemeActionPipe(this.sanitizer).transform(value);
    } else if (format === "bytesconvert") {
      return new BytesConvertFilterPipe().transform(value, 0);
    } else if (format === "epochToDate") {
      return new EpochToDateFilterPipe().transform(value);
    } else if (format === "addTime") {
      return new AddTimePipe().transform(value, 5, 30);
    } else if (format === "utcToLocale") {
      return new UtcToLocale().transform(value);
    } else if (format === "timeConverter") {
      return new TimeConverterPipe().transform(value);
    }
    else if (format === "ToLocale") {
      return new ToLocale().transform(value);
    }
    else if (format === "utcToLocaleHTML") {
      return new UtcToLocaleHTML().transform(value);
    } else if (format === "draftToComplete") {
      return new DraftToCompleteFilterPipe().transform(value);
    } else if (format === "decodeHtmlStr") {
      return new DecodeHtmlStrPipe().transform(value);
    } else if (format === "iso8601ToLocalTime") {
      return new Iso8601ToLocalTimePipe().transform(value);
    } else if (format === "cveToDate") {
      return new CveToDateFilterPipe().transform(value);
    } else if (format === "cleanString") {
      return new CleanStringPipe().transform(value);
    } else if (format === "hostnameToDomain") {
      return new HostnameToDomainPipe().transform(value);
    } else if (format === "date-Format") {
      return new Date_Format().transform(value);
    } else if (format === "enumString") {
      const prms = format.split(":");
      return new EnumStringPipe().transform(value, prms[1]);
    } else if (format === "tags") {
      return new TagsPipe().transform(value);
    } else if (format === "manualtags") {
      return new ManualTagsPipe().transform(value);
    } else if (format === "decodeHtmlEntities") {
      return new DecodeHtmlEntitiesPipe().transform(value);
    } else if (format === "dateAgo") {
      return new DateAgoPipe().transform(value);
    } else if (format === "assessmentDate") {
      return new AssessmentDateFilterPipe().transform(value);
    } else if (format === "arrayLength") {
      return new ArrayLengthPipe().transform(value);
    } else if (format === "formatTrafficUnits") {
      return new FormatTrafficUnitsPipe().transform(value);
    }
    else if (format === "indianNumber") {
      return new IndianNumberPipe().transform(value);
    }
    else if (format === "macFilter") {
      return new MacFilterPipe().transform(value);
    } else if (format && format.indexOf("arrayToObjWithEllipsis") > -1) {
      const prms = format.split(":");
      const param = { key: prms[1], ellipse: prms[2] };
      return new ArrayToObjWithEllipsisPipe().transform(value, prms[1]);
    } else if (format && format.indexOf("tofixed") > -1) {
      const prms = format.split(":");
      let decimals = prms[1] ? prms[1] : 2;
      return new ToFixedPipe().transform(value, decimals);
    } else if (format === "arrayToStr") {
      return new ArrayToStrPipe().transform(value);
    } else if (format === "arrayToStrWithEllipsis") {
      return new ArrayToStrEllipsisPipe().transform(value);
    } else if (format === "daysHoursSeconds") {
      return new DaysHoursSecondsPipe().transform(value);
    } else if (format === "utcTimeAgo") {
      return new UtcTimeAgoPipe().transform(value);
    } else if (format === "timeAgo") {
      return new TimeAgoPipe().transform(value);
    } else if (format && format.indexOf("ellipsis") > -1) {
      const prms = format.split(":");
      return new EllipsisPipe().transform(value, prms[1]);
    } else if (format && format.indexOf("fileNameFilter") > -1) {
      return new FileNameFilterPipe().transform(value);
    } else if (format && format.indexOf("maskpassword") > -1) {
      return new MaskPasswordFilterPipe().transform(value);
    } else if (format === "objectToTime") {
      return new ObjectToTimeFilterPipe().transform(value);
    } else if (format === "reportNameData") {
      return new ReportNameDataPipe().transform(value);
    } else if (format === "discoveryproto") {
      return new DiscoveryProtocolPipe().transform(value);
    } else if (format === 'highlight') {
      return new HighlightFilterPipe().transform(value);
    } else if (format === "capitalSplit") {
      return new CapitalSplitPipe().transform(value);
    } else if (format === "firewallType") {
      return new FirewallTypePipe().transform(value);
    } else if (format === "securityGrade") {
      return new SecurityGradePipe().transform(value);
    }
    return value;
  }
}

@Pipe({ name: "timeAgo" })
export class TimeAgoPipe implements PipeTransform {
  transform(value: any): string {
    if (!value) {
      return value;
    }
    const d = value.length === 10 ? new Date(value * 1000) : new Date(value);
    const now = new Date();
    const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
    const timeToUpdate = Number.isNaN(seconds)
      ? 1000
      : this.getSecondsUntilUpdate(seconds) * 1000;
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30.416));
    const years = Math.round(Math.abs(days / 365));
    if (Number.isNaN(seconds)) {
      return "";
    } else if (seconds <= 45) {
      return "A few seconds ago";
    } else if (seconds <= 90) {
      return "A minute ago";
    } else if (minutes <= 45) {
      return minutes + " minutes ago";
    } else if (minutes <= 90) {
      return "An hour ago";
    } else if (hours <= 22) {
      return hours + " hours ago";
    } else if (hours <= 36) {
      return "A day ago";
    } else if (days <= 25) {
      return days + " days ago";
    } else if (days <= 45) {
      return "A month ago";
    } else if (days <= 345) {
      return months + " months ago";
    } else if (days <= 545) {
      return "A year ago";
    } else {
      // (days > 545)
      return years + " years ago";
    }
  }

  private getSecondsUntilUpdate(seconds: number): number {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;
    if (seconds < min) {
      // less than 1 min, update every 2 secs
      return 2;
    } else if (seconds < hr) {
      // less than an hour, update every 30 secs
      return 30;
    } else if (seconds < day) {
      // less then a day, update every 5 mins
      return 300;
    } else {
      // update every hour
      return 3600;
    }
  }
}

@Pipe({ name: "utcTimeAgo" })
export class UtcTimeAgoPipe implements PipeTransform {
  transform(value: any): string {
    if (!value) {
      return value;
    }
    if (value && value.indexOf("Z") === -1) {
      value += "Z";
    }
    value =
      new Date(value).toLocaleDateString() +
      " " +
      new Date(value).toLocaleTimeString();
    const d = value.length === 10 ? new Date(value * 1000) : new Date(value);
    const now = new Date();
    const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
    const timeToUpdate = Number.isNaN(seconds)
      ? 1000
      : this.getSecondsUntilUpdate(seconds) * 1000;
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30.416));
    const years = Math.round(Math.abs(days / 365));
    if (Number.isNaN(seconds)) {
      return "";
    } else if (seconds <= 45) {
      return "A few seconds ago";
    } else if (seconds <= 90) {
      return "A minute ago";
    } else if (minutes <= 45) {
      return minutes + " minutes ago";
    } else if (minutes <= 90) {
      return "An hour ago";
    } else if (hours <= 22) {
      return hours + " hours ago";
    } else if (hours <= 36) {
      return "A day ago";
    } else if (days <= 25) {
      return days + " days ago";
    } else if (days <= 45) {
      return "A month ago";
    } else if (days <= 345) {
      return months + " months ago";
    } else if (days <= 545) {
      return "A year ago";
    } else {
      // (days > 545)
      return years + " years ago";
    }
  }

  private getSecondsUntilUpdate(seconds: number): number {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;
    if (seconds < min) {
      // less than 1 min, update every 2 secs
      return 2;
    } else if (seconds < hr) {
      // less than an hour, update every 30 secs
      return 30;
    } else if (seconds < day) {
      // less then a day, update every 5 mins
      return 300;
    } else {
      // update every hour
      return 3600;
    }
  }
}

@Pipe({
  name: "macFilter",
})
export class MacFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value || value === "") {
      return "";
    }
    return value.match(/.{1,2}/g).join(":");
  }
}

// tslint:disable-next-line:use-pipe-transform-interface
@Pipe({
  name: "orderBy",
})
export class ArrayOrderByPipe {
  private static parseExpression(expression: string): string[] {
    expression = expression.replace(/\[(\w+)\]/g, ".$1");
    expression = expression.replace(/^\./, "");
    return expression.split(".");
  }

  private static getValue(object: any, expression: string[]): any {
    for (let i = 0, n = expression.length; i < n; ++i) {
      const k = expression[i];
      if (!object || !(k in object)) {
        return;
      }
      object = object[k];
    }
    return object;
  }

  private static setValue(object: any, value: any, expression: string[]): any {
    let i;
    for (i = 0; i < expression.length - 1; i++) {
      object = object[expression[i]];
    }
    object[expression[i]] = value;
  }

  transform(value: any | any[], expression?: any, reverse?: boolean): any {
    if (!value) {
      return value;
    }
    const isArray = value instanceof Array;

    if (isArray) {
      return this.sortArray(value, expression, reverse);
    }

    if (typeof value === "object") {
      return this.transformObject(value, expression, reverse);
    }

    return value;
  }

  private sortArray(value: any[], expression?: any, reverse?: boolean): any[] {
    const isDeepLink = expression && expression.indexOf(".") !== -1;

    if (isDeepLink) {
      expression = ArrayOrderByPipe.parseExpression(expression);
    }

    const array: any[] = value.sort((a: any, b: any): number => {
      if (!expression) {
        return a > b ? 1 : -1;
      }

      if (!isDeepLink) {
        return a[expression] > b[expression] ? 1 : -1;
      }

      return ArrayOrderByPipe.getValue(a, expression) >
        ArrayOrderByPipe.getValue(b, expression)
        ? 1
        : -1;
    });

    if (reverse) {
      return array.reverse();
    }

    return array;
  }

  private transformObject(
    value: any | any[],
    expression?: any,
    reverse?: boolean
  ): any {
    const parsedExpression = ArrayOrderByPipe.parseExpression(expression);
    let lastPredicate: any = parsedExpression.pop();
    let oldValue = ArrayOrderByPipe.getValue(value, parsedExpression);

    if (!(oldValue instanceof Array)) {
      if (lastPredicate !== null) {
        parsedExpression.push(lastPredicate);
      }
      lastPredicate = null;
      oldValue = ArrayOrderByPipe.getValue(value, parsedExpression);
    }

    if (!oldValue) {
      return value;
    }

    const newValue = this.transform(oldValue, lastPredicate, reverse);
    ArrayOrderByPipe.setValue(value, newValue, parsedExpression);
    return value;
  }
}

@Pipe({ name: "safe" })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(url: any): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Pipe({ name: "RemeAction" })
export class RemeActionPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(data: any): SafeHtml {
    let content = "";
    let s = [
      "os update",
      "software patch",
      "update",
      "run remediation script",
      "uninstall",
      "software install",
      "software uninstall",
      "service required",
      "service not required",
    ];
    const icons = [
      `<i class="fas fa-exclamation-triangle mr-1 text-orange-400"></i>`,
      `<i class="fas fa-exclamation-triangle mr-1 text-orange-400"></i>`,
      `<i class="fas fa-exclamation-triangle mr-1 text-orange-400"></i>`,
      `<i class="fas fa-download mr-1 text-green-400"></i>`,
      `<i class="fas fa-trash-alt mr-1 text-red-400 blink"></i>`,
      `<i class="fas fa-download mr-1 text-green-400"></i>`,
      `<i class="fas fa-trash-alt mr-1 text-red-400 blink"></i>`,
      `<i class="fas fa-download mr-1 text-green-400"></i>`,
      `<i class="fas fa-trash-alt mr-1 blink text-red-400"></i>`,
    ];
    let index = s.indexOf(data.toLowerCase());
    if (index > -1) {
      content = icons[index] + new CamelToHumanPipe().transform(data, true);
    }
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }
}

@Pipe({ name: "safeHTML" })
export class SafeHTMLPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(url: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(url);
  }
}

@Pipe({
  name: "uppercase",
})
export class UppercasePipe implements PipeTransform {
  transform(input: any): any {
    if (!input) {
      return "";
    }
    if (typeof input === "boolean") {
      return input.toString().toUpperCase();
    }
    if (Array.isArray(input)) {
      return input.map((str: any) => str.toUpperCase());
    }
    if (typeof input !== "string") {
      return input;
    }
    return input.toUpperCase();
  }
}

@Pipe({
  name: "camelToHuman",
})
export class CamelToHumanPipe implements PipeTransform {
  transform(input: any, uppercaseFirst: any): any {
    if (typeof input !== "string") {
      return input;
    }
    let result = input.replace(/([a-z\d])([A-Z])/g, "$1" + (" " || "_") + "$2");
    if (result.indexOf("_") > -1) {
      /*result = result.replace(/(?:_| |\b)(\w)/g,
        (key, p1) => p1.toUpperCase()).replace(/([a-z])([A-Z])/g, '$1 $2'); */
      result = result.replace(
        /(?:_|\s|^)(\w)/g,
        (_, p1) => " " + p1.toUpperCase()
      );
    }
    if (uppercaseFirst) {
      result = result.charAt(0).toUpperCase() + result.slice(1);
    }
    return result;
  }
}

@Pipe({
  name: 'indianNumber'
})
export class IndianNumberPipe implements PipeTransform {
  transform(value: number | string): string {
    if (value == null) return '';
    if (value == 0) return '0';
    value = Number(value).toFixed(0)
    let numStr = value.toString().split('.');
    let lastThree = numStr[0].substring(numStr[0].length - 3);
    let otherNumbers = numStr[0].substring(0, numStr[0].length - 3);
    if (otherNumbers != '') lastThree = ',' + lastThree;
    let formattedOutput =
      otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;
    return numStr.length > 1 ? formattedOutput + '.' + numStr[1] : formattedOutput;
  }
}


@Pipe({
  name: "search",
})
export class SearchPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return null;
    }
    if (!args) {
      return value;
    }
    args = args.toLowerCase();
    return value.filter((item: any) => {
      return JSON.stringify(item).toLowerCase().includes(args);
    });
  }
}

@Pipe({
  name: "formatTrafficUnits",
})
export class FormatTrafficUnitsPipe implements PipeTransform {
  transform(units: any, decimals?: any, display?: any, base?: any): any {
    if (!units) {
      return "";
    }
    if (display === undefined) {
      display = [
        "bps",
        "Kbps",
        "Mbps",
        "Gbps",
        "Tbps",
        "Pbps",
        "Ebps",
        "Zbps",
        "Ybps",
      ];
    }
    if (units === 0) {
      return units + display[0];
    }
    base = base || 1000; // or 1024 for binary
    const dm = decimals || 2;
    const i = Math.floor(Math.log(units) / Math.log(base));
    return parseFloat((units / Math.pow(base, i)).toFixed(dm)) + display[i];
  }
}

@Pipe({
  name: "arrayToStrWithEllipsis",
})
export class ArrayToStrEllipsisPipe implements PipeTransform {
  transform(input: any, args?: any): any {
    if (!(input && input !== "")) {
      return "";
    }
    if (input.length === 1) {
      return input[0];
    } else if (input.length < 3) {
      return input.join(", ");
    } else if (input.length > 2) {
      return input[0] + ", " + input[1] + ", ...";
    } else {
      return "";
    }
  }
}

// tslint:disable-next-line:use-pipe-transform-interface
@Pipe({
  name: "ellipsis",
})
export class EllipsisPipe {
  transform(val: any, args?: any): any {
    if (!val) {
      return val;
    }
    args = !args ? 25 : args;
    if (typeof val === "object" && val.length && val.length !== undefined) {
      val = val.join(", ");
    } else if (typeof val === "object" && val.length === undefined) {
      val = JSON.stringify(val)
        .replace("{", "")
        .replace("}", "")
        .replace(/\\/g, "");
    } else {
      val = val + "";
    }
    if (val.length > args) {
      return val.substring(0, args) + "...";
    } else {
      return val;
    }
  }
}

@Pipe({
  name: "arrayToObjWithEllipsis",
})
export class ArrayToObjWithEllipsisPipe implements PipeTransform {
  transform(input: any, args?: any): any {
    if (!input || input === null || input === "") {
      return "";
    }
    const retData: any = [];
    if (input && input.length) {
      input.forEach((obj: any) => {
        retData.push(obj[args]);
      });
    }
    if (retData && retData.length) {
      return retData.join(", ");
    } else {
      return "";
    }
  }
}

@Pipe({
  name: "arrayToStr",
})
export class ArrayToStrPipe implements PipeTransform {
  transform(input: any, args?: any): any {
    if (!input || input === null || input === "") {
      return "";
    }
    if (input.length > 0) {
      return input.join(", ");
    } else {
      return "";
    }
  }
}

@Pipe({
  name: "strToMac",
})
export class StrToMacPipe implements PipeTransform {
  transform(input: any, uppercase: any): any {
    if (!input || input === null || input === "") {
      return "";
    }
    if (uppercase) {
      input = input.toUpperCase();
    }
    if (input.length >= 3 && input.length <= 16) {
      input = input.replace(/\W/gi, "");
      input = input.replace(/(.{2})/g, "$1:");
      return input;
    } else {
      return "";
    }
  }
}

@Pipe({
  name: "maskPassword",
})
export class MaskPasswordFilterPipe implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return "***********";
    }
    return "***********";
  }
}

@Pipe({
  name: "cveToDate",
})
export class CveToDateFilterPipe implements PipeTransform {
  transform(epoch: any): any {
    if (!epoch) {
      return "-";
    }
    epoch = epoch + "";
    const year = epoch.slice(0, 4);
    const month = epoch.slice(4, 6);
    const date = epoch.slice(6, 8);
    return year + "/" + month + "/" + date;
  }
}

@Pipe({
  name: "assessmentDate",
})
export class AssessmentDateFilterPipe implements PipeTransform {
  transform(epoch: any): any {
    if (!epoch) {
      return "-";
    }
    const ret: string[] = [];
    if (epoch.length > 0) {
      epoch.forEach((dt: string | number | Date) => {
        dt = dt + "";
        dt = dt.split(".")[0];
        // @ts-ignore
        const d: any =
          dt.toString().length === 10
            ? new Date(parseInt(dt) * 1000)
            : new Date(dt);
        ret.push(d.toLocaleDateString() + " " + d.toLocaleTimeString());
      });
    }
    return ret.join(", ");
  }
}

@Pipe({ name: "split" })
export class SplitPipe implements PipeTransform {
  transform(input: any, separator: string = " ", limit: number = 0): any {
    if (typeof input === "string") {
      return input.split(separator)[limit];
    }
    return input;
  }
}

@Pipe({ name: "arrayLength" })
export class ArrayLengthPipe implements PipeTransform {
  transform(input: any): any {
    if (Array.isArray(input)) {
      return input.length;
    }
    return 0;
  }
}
// @Pipe({
//   name: 'epochToDate'
// })
// export class EpochToDateFilterPipe implements PipeTransform {
//   transform(epoch: any): any {
//     if (!epoch) {
//       return '-';
//     }
//     epoch = (epoch + '').split('.')[0];
//     const d = (epoch.toString().length === 10) ? new Date(epoch * 1000) : new Date(+epoch);
//     return d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
//   }
// }
@Pipe({
  name: "epochToDate",
})
export class EpochToDateFilterPipe implements PipeTransform {
  transform(epoch: any): any {
    const format = localStorage.getItem("customisedDateFormat");
    if (!epoch) {
      return "-";
    }
    epoch = (epoch + "").split(".")[0];
    const d =
      epoch.toString().length === 10
        ? new Date(epoch * 1000)
        : new Date(+epoch);
    switch (format) {
      case "shortDate":
        return this.formatDate(d, "shortDate");
      case "mediumDate":
        return this.formatDate(d, "mediumDate");
      case "yyyy-MM-dd":
        return this.formatDate(d, "yyyy-MM-dd");
      default:
        return this.formatDate(d, "short");
    }
  }
  private formatDate(date: Date, dateFormat: string): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    if (dateFormat === "yyyy-MM-dd") {
      return `${year}/${month}/${day}` + " " + date.toLocaleTimeString();
    } else if (dateFormat === "mediumDate") {
      return `${day}/${month}/${year}` + " " + date.toLocaleTimeString();
    } else if (dateFormat === "shortDate") {
      return `${month}/${day}/${year}` + " " + date.toLocaleTimeString();
    } else {
      return (
        date.toLocaleDateString(undefined, {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        }) +
        " " +
        date.toLocaleTimeString()
      );
    }
  }
}

@Pipe({
  name: "enumFilterPipe",
})
export class EnumFilterPipe implements PipeTransform {
  transform(input: any, type: string): any {
    let globalType = {
      assetImportance: { 25: "LOW", 50: "MEDIUM", 75: "HIGH", 100: "CRITICAL" },
      agentType: { 3: "LIGHTWEIGHT AGENT" },
    };
    return globalType[type][input];
  }
}

@Pipe({
  name: "bytesConvert",
})
export class BytesConvertFilterPipe implements PipeTransform {
  transform(bytes: any, decimals: number = 2): any {
    if (typeof bytes === "string") {
      bytes = Number(bytes);
    }
    if (bytes <= 0 || !bytes || bytes === "" || bytes === null) {
      return "0";
    }
    const units = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
    let unit = 0;
    while (bytes >= 1024) {
      bytes /= 1024;
      unit++;
    }
    return bytes.toFixed(+decimals) + " " + units[unit];
  }
}

@Pipe({
  name: "daysHoursSeconds",
})
export class DaysHoursSecondsPipe implements PipeTransform {
  transform(seconds: any, args?: any): any {
    if (!seconds) {
      return seconds;
    } else {
      const secondsInMinute = 60;
      const secondsInHour = secondsInMinute * 60;
      const secondsInDay = secondsInHour * 24;
      const secondsInMonth = secondsInDay * 30.44; // Approximation based on an average month length
      const secondsInYear = secondsInMonth * 12;
      const years = Math.floor(seconds / secondsInYear);
      const months = Math.floor((seconds % secondsInYear) / secondsInMonth);
      const days = Math.floor((seconds % secondsInMonth) / secondsInDay);
      const hours = Math.floor((seconds % secondsInDay) / secondsInHour);
      const minutes = Math.floor((seconds % secondsInHour) / secondsInMinute);
      const remainingSeconds = seconds % secondsInMinute;
      const parts = [];
      if (years > 0) parts.push(`${years}y`);
      if (months > 0) parts.push(`${months}mo`);
      if (days > 0) parts.push(`${days}d`);
      if (hours > 0) parts.push(`${hours}h`);
      if (minutes > 0) parts.push(`${minutes}m`);
      if (remainingSeconds > 0) parts.push(`${remainingSeconds}s`);
      return parts.join(" ");
    }
  }
}

@Pipe({
  name: "timeFormat",
})
export class TimeFormat implements PipeTransform {
  transform(time: any, args?: any): any {
    let content = "";
    if (time !== "") {
      content = time.Hour + "h :" + time.Minute + "m :" + time.Second + "s";
      return content;
    }
    return content;
  }
}

@Pipe({
  name: "dateFormat",
})
export class DateFormat implements PipeTransform {
  transform(date: any, args?: any): any {
    let content = "";
    if (date !== "") {
      content = date.Year + "-" + date.Month + "-" + date.Day;
      return content;
    }
    return content;
  }
}

@Pipe({
  name: "date-Format",
})
export class Date_Format implements PipeTransform {
  transform(date: any, args?: any): any {
    let content = "";
    if (date !== "") {
      content = date.replaceAll("/", "-")
      return content;
    }
    return content;
  }
}

@Pipe({
  name: "dateAndTimeFormat",
})
export class DateAndTimeFormat implements PipeTransform {
  transform(dateAndTime: any, args?: any): any {
    let date = "-";
    let time = "-";
    if (dateAndTime !== "") {
      date =
        dateAndTime.Date.Year +
        "-" +
        dateAndTime.Date.Month +
        "-" +
        dateAndTime.Date.Day;
      time =
        dateAndTime.Time.Hour +
        "h :" +
        dateAndTime.Time.Minute +
        "m :" +
        dateAndTime.Time.Second +
        "s";
      return date + " " + time;
    }
    return date + " " + time;
  }
}

@Pipe({
  name: "cleanString",
})
export class CleanStringPipe implements PipeTransform {
  transform(inputString: any): any {
    if (!inputString) {
      return inputString;
    }
    // Use the replace method to convert double backslashes to single backslashes
    // return inputString.replace(/\\+/g, '\\').replace(/\\\"/g, '"').replace(/\\\\"/g, '"').replace(/\"/g, '');
    return inputString.replace(/\\+/g, "/").replaceAll('/n', "\n");
  }
}

@Pipe({
  name: "hostnameToDomain",
})
export class HostnameToDomainPipe implements PipeTransform {
  transform(input: any): any {
    if (!input) {
      return input;
    }
    const knownTLDs = ['com', 'net', 'org', 'edu', 'gov', 'au', 'uk', 'co']; // Add more TLDs as needed
    const lowerCaseUrl = input.toLowerCase();

    for (let tld of knownTLDs) {
      const tldIndex = lowerCaseUrl.indexOf(`.${tld}`);
      if (tldIndex !== -1) {
        return input.split('.')[0];
      }
    }

    return input;
  }
}

@Pipe({
  name: "enumString",
})
export class EnumStringPipe implements PipeTransform {
  transform(inputString: any, args?: any): any {
    if (!inputString) {
      return inputString;
    }
    if (args) {
      const typeMatch = inputString.match(/of type (\w+)/);
      if (typeMatch && typeMatch[1]) {
        const typeKey = typeMatch[1];
        if (enum_data.hasOwnProperty(typeKey)) {
          const updatedString = inputString.replace(
            typeMatch[0],
            `of type ${enum_data[typeKey]}`
          );
          return updatedString;
        } else {
          return inputString;
        }
      } else {
        return inputString;
      }
    } else {
      if (!enum_data[inputString]) {
        return inputString;
      }
      return enum_data[inputString];
    }
  }
}

@Pipe({
  name: "tags",
})
export class TagsPipe implements PipeTransform {
  transform(input: any): any {
    if (typeof input === 'string') {
      input = JSON.parse(input);
    }
    if (Object.keys(input).length === 0) {
      return [];
    }
    const outputArray = Object.entries(input).map(([key, value]) => ({
      key,
      value: value, // Assuming there's only one value in the array
    }));
    return outputArray;
  }
}

@Pipe({
  name: "textbadge",
})
export class TextBadgePipe implements PipeTransform {
  transform(input: any, split: string = ","): any {
    if (!input) {
      return input;
    }
    if (typeof input === "string") {
      return input.split(split);
    }
    return input;
  }
}

@Pipe({
  name: "manualtags",
})
export class ManualTagsPipe implements PipeTransform {
  transform(input: any): any {
    if (typeof input === 'string') {
      input = JSON.parse(input);
    }
    if (Object.keys(input).length === 0) {
      return [];
    }
    const outputArray = Object.entries(input).map(([key, value]) => ({
      key,
      value,
    }));
    return outputArray;
  }
}

@Pipe({
  name: "decodeHtmlEntities",
})
export class DecodeHtmlEntitiesPipe implements PipeTransform {
  transform(input: any): any {
    if (!input) {
      return input;
    }

    input = input.replace(/nu003cbr\/u003enu003cbr\/u003en/g, "")
      .replace(/ntu003cpu003eu003cstrongu003e/g, "")
      .replace(/nnn/g, "")
      .replace(/u003cbr\/u003e/g, "")
      .replace(/u0026/g, "")
      .replace(/nbsp;/g, " ")
      .replace(/n   /g, "")
      .replace(/enu00/g, "eu00")
      .replace(/u00/g, "\\u00")
      .replace(/nttt/g, "")
      .replace(/ntt/g, "")
      .replace(/\.nt/g, ".")
      .replace(/ntM/g, "M")
      .replace(/lsquo;/g, "'")
      .replace(/rsquo;/g, "'")
      .replace(/&lsquo;/g, "'")
      .replace(/&rsquo;/g, "'")
      .replace(/ldquo;/g, "'")
      .replace(/rdquo;/g, "'")
      .replace(/<[^>]*>/g, "")
      .replace(/\s*nt$/, "");

    return input.replace(/\\u([\d\w]{4})/gi, (match: any, grp: any) => {
      return String.fromCharCode(parseInt(grp, 16));
    });
  }
}

